import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Наші послуги | Оренда яхт AzureWave</title>
        <meta
          name={"description"}
          content={"Безперешкодне плавання з кожним чартером"}
        />
        <meta
          property={"og:title"}
          content={"Наші послуги | Оренда яхт AzureWave"}
        />
        <meta
          property={"og:description"}
          content={"Безперешкодне плавання з кожним чартером"}
        />
        <meta
          property={"og:image"}
          content={"https://up.lixorev.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://up.lixorev.com/img/image93.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://up.lixorev.com/img/image93.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="120px 0 120px 0"
        background="linear-gradient(rgb(34 81 31 / 54%) 1%, rgb(0 0 0 / 89%) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://up.lixorev.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box"
        md-padding="80px 0 90px 0"
        quarkly-title="Headline-2"
      >
        <Override slot="SectionContent" sm-min-width="280px" />
        <Text
          as="h1"
          margin="0px 0px 20px 0px"
          font="normal 700 44px/1.2 --fontFamily-sans"
          color="--light"
          sm-font="normal 700 42px/1.2 --fontFamily-sans"
        >
          Першокласний яхтинг
        </Text>
        <Text
          margin="0px 180px 80px 0px"
          font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
          color="#aab1b9"
          lg-margin="0px 0 50px 0px"
          md-margin="0px 0 50px 0px"
        >
          У AzureWave Yacht Charters ми прагнемо надати винятковий спектр
          послуг, створених для того, щоб покращити ваш яхтовий досвід. Від
          персоналізованих подорожей до всебічних зручностей на борту, ми
          гарантуємо, що кожен аспект вашої подорожі буде бездоганним і
          розкішним без екстравагантності великих суперяхт.
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="25px 25px"
          md-grid-template-columns="1fr"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            background="rgba(71, 70, 124, 0.25)"
            padding="25px 20px 25px 20px"
            border-width="1px"
            border-style="solid"
            border-color="rgba(74, 74, 108, 0.4)"
            border-radius="25px"
            lg-flex-direction="column"
          >
            <Box min-width="100px" min-height="100px">
              <Text
                margin="0px 0px 12px 0px"
                color="--light"
                font="normal 500 22px/1.2 --fontFamily-sans"
              >
                Індивідуальні маршрути
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="#aab1b9"
              >
                Персональне планування: Попрацюйте з нашою командою, щоб
                розробити маршрут, який охоплює всі бажані пункти призначення.
                <br />
                <br />
                Гнучке планування: Обирайте найкращий час для своєї подорожі -
                від кількох годин до кількох днів.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            background="rgba(71, 70, 124, 0.25)"
            padding="25px 20px 25px 20px"
            border-width="1px"
            border-style="solid"
            border-color="rgba(74, 74, 108, 0.4)"
            border-radius="25px"
            lg-flex-direction="column"
          >
            <Box min-width="100px" min-height="100px">
              <Text
                margin="0px 0px 12px 0px"
                color="--light"
                font="normal 500 22px/1.2 --fontFamily-sans"
              >
                Різноманітність яхт
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="#aab1b9"
              >
                Одноденні вітрильники: Ідеально підходять для швидких подорожей
                і невеликих груп.
                <br />
                <br />
                Яхти з ночівлею: Оснащені комфортабельними каютами для більш
                тривалих і спокійних подорожей.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            background="rgba(71, 70, 124, 0.25)"
            padding="25px 20px 25px 20px"
            border-width="1px"
            border-style="solid"
            border-color="rgba(74, 74, 108, 0.4)"
            border-radius="25px"
            lg-flex-direction="column"
          >
            <Box min-width="100px" min-height="100px">
              <Text
                margin="0px 0px 12px 0px"
                color="--light"
                font="normal 500 22px/1.2 --fontFamily-sans"
              >
                Досвідчені екіпажі
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="#aab1b9"
              >
                Кваліфікована навігація: Кожна яхта укомплектована досвідченим
                капітаном і командою.
                <br />
                <br />
                Персоналізований сервіс: Члени екіпажу уважно ставляться до всіх
                ваших потреб, забезпечуючи безтурботну подорож.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            background="rgba(71, 70, 124, 0.25)"
            padding="25px 20px 25px 20px"
            border-width="1px"
            border-style="solid"
            border-color="rgba(74, 74, 108, 0.4)"
            border-radius="25px"
            lg-flex-direction="column"
          >
            <Box min-width="100px" min-height="100px">
              <Text
                margin="0px 0px 12px 0px"
                color="--light"
                font="normal 500 22px/1.2 --fontFamily-sans"
              >
                Зручності на борту
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="#aab1b9"
              >
                Варіанти харчування: Насолоджуйтесь вишуканими стравами,
                приготованими на ваш смак.
                <br />
                <br />
                Спорядження для відпочинку: Доступ до спорядження для водних
                видів спорту та дозвілля.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            background="rgba(71, 70, 124, 0.25)"
            padding="25px 20px 25px 20px"
            border-width="1px"
            border-style="solid"
            border-color="rgba(74, 74, 108, 0.4)"
            border-radius="25px"
            lg-flex-direction="column"
          >
            <Box min-width="100px" min-height="100px">
              <Text
                margin="0px 0px 12px 0px"
                color="--light"
                font="normal 500 22px/1.2 --fontFamily-sans"
              >
                Безпека та захист
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="#aab1b9"
              >
                Найкраще обладнання для безпеки: Повністю обладнані
                найсучаснішим захисним спорядженням.
                <br />
                <br />
                Безперервне навчання: Регулярно оновлювані тренінги для всіх
                членів екіпажу, щоб впоратися з будь-якою ситуацією.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            background="rgba(71, 70, 124, 0.25)"
            padding="25px 20px 25px 20px"
            border-width="1px"
            border-style="solid"
            border-color="rgba(74, 74, 108, 0.4)"
            border-radius="25px"
            lg-flex-direction="column"
          >
            <Box min-width="100px" min-height="100px">
              <Text
                margin="0px 0px 12px 0px"
                color="--light"
                font="normal 500 22px/1.2 --fontFamily-sans"
              >
                Екологічно чисті практики
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="#aab1b9"
              >
                Сталий розвиток: Ми прагнемо мінімізувати наш вплив на
                навколишнє середовище під час перебування в морі.
                <br />
                <br />
                Еко-свідомі зручності: Використання продуктів, що біологічно
                розкладаються, та відповідальне поводження з відходами.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="List-5">
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="56px 34px"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
          sm-grid-gap="36px 0"
          md-grid-gap="50px 0"
          margin="0px 130px 0px 130px"
          lg-margin="0px 0 0px 0"
          md-grid-template-columns="1fr"
        >
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="column"
            padding="0px 50px 0px 0px"
            align-items="flex-start"
            md-padding="0px 0 0px 0px"
          >
            <Text
              margin="0px 0px 25px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              md-margin="0px 0px 20px 0px"
            >
              Дізнатися більше та забронювати
            </Text>
            <Text
              margin="0px 0px 35px 0px"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              color="#474a4d"
              md-margin="0px 0px 25px 0px"
            >
              Хочете дізнатися більше про наші пропозиції або готові забронювати
              наступну морську пригоду? Завітайте до нашого офісу на набережній
              або зв'яжіться з нами напряму. Ми тут, щоб надати вам всю
              необхідну інформацію для прийняття обґрунтованого рішення і
              допомогти адаптувати ваш ідеальний яхтовий досвід.
              <br />
              <br />
              Дозвольте AzureWave Yacht Charters стати вашим гідом по морю.
              Незалежно від того, чи це спокійний вихідний день, чи захоплююча
              багатоденна екскурсія, ми прагнемо зробити ваш яхтовий досвід
              максимально приємним і безтурботним.
            </Text>
            <Button
              href="/contacts"
              type="link"
              text-decoration-line="initial"
              background="--color-primary"
              padding="11px 28px 11px 28px"
              border-radius="25px"
            >
              Контакти
            </Button>
          </Box>
          <Image
            src="https://up.lixorev.com/img/5.jpg"
            display="block"
            width="100%"
            md-order="-1"
          />
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
